import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/scribe-jobs'

const JOB_TYPES = [
  {
    value: 'General',
    label: 'General',
  },
  {
    value: 'Keyworker',
    label: 'Keyworker',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
    ],
  },
  {
    value: 'AssuranceAllianceActionPlan',
    label: 'CSAP',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC03tdo3hf", // Interventions Alliance
    ],
  },
  {
    value: 'SkillsPassport',
    label: 'Skills Passport',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC014yedle", // invisible walls cic
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
    ],
  },
  {
    value: 'QAndA',
    label: 'Q&A',
  },
  {
    value: 'DST',
    label: 'DST',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC02r6ppgz", // Hereford and Worcestershire ICB
    ],
  },
  {
    value: 'PurpleVisits',
    label: 'Purple Visits',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01zhmrgm", // Rampton Hospital
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC03wx6v7r", // The State Hospital
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC037te191", // British Telecom
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
      "LC01lrfxmr", // ARC Serco
    ],
  },
  {
    value: 'HealthcareAssessment',
    label: 'Healthcare Assessment',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01zhmrgm", // Rampton Hospital
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC03wx6v7r", // The State Hospital
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC037te191", // British Telecom
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
      "LC01lrfxmr", // ARC Serco
    ],
  },
  {
    value: 'ChildProtectionStrategyMeeting',
    label: 'Child Protection Strategy Meeting',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
    ],
  },
  {
    value: 'SercoCaseManagement',
    label: 'Serco Case Management',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      'LC01tspy7j', // Made Purple
      'LC01lrfxmr', // ARC Serco
      'LC037kl239', // Serco ASPAC
    ]
  },
  {
    value: 'PresentationReview',
    label: 'Presentation Review',
  },
  {
    value: 'PrisonerPhoneCall',
    label: 'Prisoner Phone Call',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01zhmrgm", // Rampton Hospital
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC03wx6v7r", // The State Hospital
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC037te191", // British Telecom
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
      "LC01lrfxmr", // ARC Serco
    ],
  },
  {
    value: 'BusinessCall',
    label: 'Business Call',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC00kwndb8", // Demo Singapore
    ],
  }
]

const SUMMARY_TYPES = [
  {
    value: 'General',
    label: 'General',
  },
  {
    value: 'Keyworker',
    label: 'Keyworker',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
    ],
  },
  {
    value: 'QuestionAndAnswer',
    label: 'Q&A',
  },
  {
    value: 'AssuranceAllianceActionPlan',
    label: 'CSAP',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC03tdo3hf", // Interventions Alliance
    ],
  },
  {
    value: 'SkillsPassport',
    label: 'Skills Passport',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC014yedle", // invisible walls cic
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
    ],
  },
  {
    value: 'DST',
    label: 'DST',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC02r6ppgz", // Hereford and Worcestershire ICB
    ],
  },
  {
    value: 'PurpleVisits',
    label: 'Purple Visits',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01zhmrgm", // Rampton Hospital
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC03wx6v7r", // The State Hospital
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC037te191", // British Telecom
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
      "LC01lrfxmr", // ARC Serco
    ],
  },
  {
    value: 'HealthcareAssessment',
    label: 'Healthcare Assessment',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01zhmrgm", // Rampton Hospital
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC03wx6v7r", // The State Hospital
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC037te191", // British Telecom
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
      "LC01lrfxmr", // ARC Serco
    ],
  },
  {
    value: 'ChildProtectionStrategyMeeting',
    label: 'Child Protection Strategy Meeting',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
    ],
  },
  {
    value: 'ChildProtectionStrategyMeetingPDF',
    label: 'Child Protection Strategy Meeting PDF',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
    ],
  },
  {
    value: 'MeetingActionAndTakeaways',
    label: 'Meeting Actions And Takeaways',
  },
  {
    value: 'SercoCaseManagement',
    label: 'Serco Case Management',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      'LC01tspy7j', // Made Purple
      'LC01lrfxmr', // ARC Serco
      'LC037kl239', // Serco ASPAC
    ]
  },
  {
    value: 'PresentationReview',
    label: 'Presentation Review',
  },
  {
    value: 'PrisonerPhoneCall',
    label: 'Prisoner Phone Call',
    enterprises: [
      // UK
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC01zhmrgm", // Rampton Hospital
      "LC01v6us2d", // HMP Parc
      "LC02tnrza8", // Oakhill STC
      "LC04fo1vo8", // HMP Five Wells
      "LC04fktvk4", // HMP Oakwood
      "LC03wx6v7r", // The State Hospital
      "LC014kgv6h", // HMP Dovegate
      "LC049hbb5u", // HMP Ashfield
      "LC00jz3ish", // Isle of man prison
      "LC037te191", // British Telecom
      "LC00gkbq52", // HMP Thameside
      "LC017vaqf4", // HMP Fosse Way
      "LC01nj8bm8", // HMP Doncaster
      "LC00l5ik0e", // HMP Rye Hill
      "LC02k77qsd", // HMP Altcourse
      "LC00kwndb8", // Demo Singapore
      // AUS
      "LC037kl239", // Serco ASPAC
      "LC01lrfxmr", // ARC Serco
    ],
  },
  {
    value: 'BusinessCall',
    label: 'Business Call',
    enterprises: [
      "LC0332se5x", // Made Purple Dev
      "LC01tspy7j", // Made Purple
      "LC00kwndb8", // Demo Singapore
    ],
  }
]

export default {
  list(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  get(id) {
    return axios.get(`${baseUrl}/${id}`)
  },
  delete(id) {
    return axios.delete(`${baseUrl}/${id}`)
  },
  create(scribeJob) {
    return axios.post(`${baseUrl}`, scribeJob)
  },
  upload(id, file) {
    const formData = new FormData()
    formData.append('file', file)

    return axios.post(`${baseUrl}/${id}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  uploadV2(id, file) {
    const formData = new FormData()
    formData.append('file', file)

    return axios.post(`${baseUrl}/${id}/upload/v2`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  },
  update(id, jobDto) {
    return axios.put(`${baseUrl}/${id}`, jobDto)
  },
  updateSpeaker(id, label) {
    return axios.put(`scribe-speakers/${id}`, {speaker_label: label})
  },
  getAudioFile(id) {
    return axios.get(`${baseUrl}/${id}/file`, {responseType: "blob"})
  },
  generateSummary(jobID, summaryType) {
    return axios.post(`${baseUrl}/${jobID}/summary`, {summary_type: summaryType})
  },
  updateTranscript(id, dto) {
    return axios.put(`/scribe-transcriptions/${id}`, dto)
  },
  updateSummary(id, dto) {
    return axios.put(`/scribe-summaries/${id}`, dto)
  },
  getCreatedJobs(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/stats/created${queryString}`);
  },
  getSessionInfo(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/stats/session-info${queryString}`);
  },
  getJobTypes(enterpriseUid) {
    const jobTypes = []
    JOB_TYPES.forEach(jobType => {
      if (!jobType.enterprises || jobType.enterprises.includes(enterpriseUid)) {
        jobTypes.push(jobType);
      }
    });
    return jobTypes;
  },
  getSummaryTypes(enterpriseUid) {
    const summaryTypes = []
    SUMMARY_TYPES.forEach(summaryType => {
      if (!summaryType.enterprises || summaryType.enterprises.includes(enterpriseUid)) {
        summaryTypes.push(summaryType);
      }
    });
    return summaryTypes
  }
}

<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>
          <slot name="title">Sessions Created</slot>
        </b-card-title>
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body v-if="!loading">
      <chartjs-component-bar-chart v-if="chartDataReady" v-show="!noData"
                                   ref="topChart"
                                   :height="300"
                                   :data="chartData"
                                   :options="chartjsData.latestBarChart.options"
      />

      <p v-if="!noData" class="mt-2 mb-0"><b>Total Sessions:</b> {{ totalSessions }}</p>

      <b-alert v-show="noData" variant="primary" show>
        <div class="alert-body">
          <span>No sessions found for this period</span>
        </div>
      </b-alert>

    </b-card-body>
  </b-card>
</template>

<script>
import helperService from '@/services/HelperService';
import { $themeColors } from '@themeConfig';
import ChartjsComponentBarChart from "@/components/charts/charts-components/ChartjsComponentBarChart.vue";
import chartjsData from "@/components/charts/chartjsData";
import PurpleScribeService from "@/services/PurpleScribeService";

export default {
  components: {
    ChartjsComponentBarChart,
  },
  props: {
    from: {
      required: false,
      type: String,
      default: '',
    },
    to: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartDataReady: false,
      loading: true,
      chartjsData,
      noData: false,
      filters: {
        from: new Date(new Date().setDate(new Date().getDate() -  7)).toDateString(),
        to: new Date().toDateString(),
      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: $themeColors.primary,
            borderColor: 'transparent',
          },
        ],
      },
      totalSessions: 0,
    }
  },
  watch: {
    from: function() {
      this.filters.from = this.from;
      this.filters.to = this.to;
      this.getData()
    },
    to: function() {
      this.filters.from = this.from;
      this.filters.to = this.to;
      this.getData()
    },
  },
  mounted() {
    if (this.from !== null) {
      this.filters.from = this.from;
    }

    if (this.to !== null) {
      this.filters.to = this.to;
    }

    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        const {data} = await PurpleScribeService.getCreatedJobs(this.filters)
        // Clear the chart
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
        this.totalSessions = 0

        let noValues = true

        data.data.forEach(item => {
          this.chartData.labels.push(item.date)
          this.chartData.datasets[0].data.push(item.count)
          if (item.count > 0) {
            noValues = false
          }
        })

        this.chartDataReady = true
        this.noData = data.data.length === 0 || noValues

        // Set total session
        this.totalSessions = data.total
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get session stats, please refresh and try again')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

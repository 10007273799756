<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="activity-page">
    <b-card class="" title="Purple Scribe Sessions Dashboard">
      <b-card-text>
        This page shows all stats for Purple Scribe sessions.
      </b-card-text>
    </b-card>

    <date-range-selector @update="updateDateRange" />

    <b-row class="match-height">
      <b-col sm="12">
        <jobs-per-day :from="fromDateISOString" :to="toDateISOString"/>
      </b-col>
      <b-col sm="12">
        <session-stats :from="fromDateISOString" :to="toDateISOString"/>
      </b-col>
    </b-row>
  </div>
</template>
<!--eslint-enable-->

<script>
import DateRangeSelector from "@/components/charts/DateRangeSelector.vue";
import JobsPerDay from "@/views/purpleScribe/sections/JobsPerDay.vue";
import SessionStats from "@/views/purpleScribe/sections/SessionStats.vue";

export default {
  name: 'Activities',
  components: {
    SessionStats,
    JobsPerDay,
    DateRangeSelector,
  },
  data() {
    return {
      from: new Date(new Date().setDate(new Date().getDate() -  7)),
      to: new Date(),
    };
  },
  computed: {
    fromDateISOString() {
      let date = new Date(this.from);
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    },
    toDateISOString() {
      let date = new Date(this.to);
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    },
  },
  mounted() {
    this.from.setHours(0,0,0,0)
    this.to.setHours(23,59,59)
  },
  methods: {
    updateDateRange(from, to) {
      this.from = from
      this.to = to
    }
  },
};
</script>

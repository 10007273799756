<template>
  <b-card no-body title="Session Stats">
    <b-card-header>
      <b-card-title>Session Stats</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>

        <b-col sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="LayersIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <span v-if="totalSeconds === 0">0</span>
                <span v-else>{{ totalSeconds | formatSeconds }}</span>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Total Processed
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col sm="6" class="mb-2 mb-xl-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon size="24" icon="ClockIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <span v-if="avgSeconds === 0">0</span>
                <span v-else>{{ avgSeconds | formatSeconds }}</span>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Average Session Duration
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>

  </b-card>
</template>

<script>

import helperService from "@/services/HelperService";
import PurpleScribeService from "@/services/PurpleScribeService";

export default {
  props: {
    from: {
      required: false,
      type: String,
      default: '',
    },
    to: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      totalSeconds: 0,
      avgSeconds: 0,
      filters: {
        from: new Date(new Date().setDate(new Date().getDate() -  7)).toDateString(),
        to: new Date().toDateString(),
      }
    }
  },
  watch: {
    from: function() {
      this.filters.from = this.from;
      this.filters.to = this.to;
      this.getData()
    },
    to: function() {
      this.filters.from = this.from;
      this.filters.to = this.to;
      this.getData()
    },
  },
  mounted() {
    if (this.from !== null) {
      this.filters.from = this.from;
    }

    if (this.to !== null) {
      this.filters.to = this.to;
    }

    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        const {data} = await PurpleScribeService.getSessionInfo(this.filters)
        this.totalSeconds = data.total_seconds
        this.avgSeconds = data.average_session_length_seconds
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get session info stats, please refresh and try again')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
